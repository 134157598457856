import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { AlertController, IonInput, ModalController } from "@ionic/angular";
import {
  Asset,
  IBudgetScope,
  Investment,
  InvestmentBudgetOrigin,
  InvestmentSlice,
  InvestmentSpendingSlice,
  InvestmentStatus,
  PerimeterBudgetPlan,
  PriceSheet,
  getInvestmentSliceFullPrice,
} from "@structs";
import { FormattedPriceInvestmentSlice } from "../investment-budget-slices.page";
import { Observable, Subscription, forkJoin } from "rxjs";
import { InvestmentsService } from "@services/investments.service";
import { TranslateService } from "@ngx-translate/core";
import { PerimeterBudgetPlansService } from "@services/perimeter-budget-plan.service";
import { ScopeService } from "@services/scope.service";
import { map, switchMap } from "rxjs/operators";
import { I18nService } from "@services/i18n.service";
import { CurrencyPipe } from "src/app/pipes/currency/currency.pipe";
import { NavigationExtras } from "@angular/router";
import { PriceSheetsListPage } from "src/app/pages/price-sheets-list/price-sheets-list.page";
import { AssetEditService } from "@services/asset-edit.service";

@Component({
  selector: "app-slice-detail",
  templateUrl: "./slice-detail.component.html",
  styleUrls: ["./slice-detail.component.scss"],
})
export class SliceDetailComponent implements OnInit, OnDestroy {
  @Input() public slice: FormattedPriceInvestmentSlice = null;
  @Input() public selectedBudgetOriginId: number = null;
  @Input() public investment: Investment = null;
  @Input() public asset: Asset = null;
  @Input() public addMode: boolean = false;
  @Input() public modalMode: boolean = true;
  @Input() public investmentStatuses: InvestmentStatus[];
  public budgetOrigins: InvestmentBudgetOrigin[] = [];
  private subscriptions: Subscription[] = [];
  public years: number[] = [];
  public pendingChanges: boolean = false;
  public additionalBudgetEditingSlice: FormattedPriceInvestmentSlice;
  private perimeterBudgetPlans: PerimeterBudgetPlan[];
  @Output() public sliceChangedEvent = new EventEmitter<any>();
  public readOnly: boolean;
  public priceSheets$: Observable<PriceSheet[]>;
  @Input() public openPriceSheetsListEvent: EventEmitter<any> = null;
  public selectedInvestmentStatusId: number = null;
  public allBudgetScopes: IBudgetScope[] = [];
  private budgetScopesChanged: boolean = false;
  private defaultYear: number = null;

  constructor(
    private modalCtrl: ModalController,
    private investmentsService: InvestmentsService,
    private translate: TranslateService,
    private alertCtrl: AlertController,
    private perimeterBudgetPlansService: PerimeterBudgetPlansService,
    private scope: ScopeService,
    private i18n: I18nService,
    private currencyPipe: CurrencyPipe
  ) {}

  ngOnInit() {
    this.subscriptions.push(
      this.investmentsService.getBudgetOrigins().subscribe(budgetOrigins => {
        this.budgetOrigins = budgetOrigins;
        if (this.investment.investmentBudgetOrigin !== null) {
          this.selectedBudgetOriginId = this.investment.investmentBudgetOrigin.id;
        } else {
          // Pick the default budget origin
          const defaultBudgetOrigin = budgetOrigins.find(origin => origin.is_default);
          if (defaultBudgetOrigin) {
            this.selectedBudgetOriginId = defaultBudgetOrigin.id;
          }
        }
      }),
      this.investmentsService.getBudgetScopes().subscribe((budgetScopes: IBudgetScope[]) => {
        this.allBudgetScopes = budgetScopes.sort((a, b) => a.order - b.order);
        if (this.addMode && budgetScopes) {
          budgetScopes.forEach(scope => {
            if (scope.is_default && !this.investment.budgetScopes.includes(scope.id)) {
              this.investment.budgetScopes.push(scope.id);
            }
          });
        }
      })
    );
    if (this.openPriceSheetsListEvent) {
      this.subscriptions.push(
        this.openPriceSheetsListEvent.subscribe(() => {
          this.openPriceSheetsList();
        })
      );
    }
    this.defaultYear = this.getAssetEndOfLifeYear();
    this.initYears();
    if (this.addMode) {
      this.subscriptions.push(
        this.scope
          .getSelectedPerimeter()
          .pipe(switchMap(perimeter => this.perimeterBudgetPlansService.getPerimeterBudgetPlans(perimeter.id, false)))
          .subscribe(plans => {
            if (plans && plans.length > 0) {
              this.perimeterBudgetPlans = plans;
            } else {
              this.perimeterBudgetPlans = [];
            }
          })
      );
    }

    if (!this.slice) {
      if (this.investment.slices.length === 0) {
        this.slice = new FormattedPriceInvestmentSlice(
          null,
          this.defaultYear,
          this.investment.status,
          null,
          0,
          "",
          false,
          ""
        );
      } else {
        const slice = this.investment.slices.find(s => s.status.id === this.investment.status.id);
        this.slice = new FormattedPriceInvestmentSlice(
          slice && slice.id,
          slice.year,
          this.investment.status,
          slice && slice.price,
          slice && slice.additionalPrice,
          slice && slice.addPriceReason,
          slice && slice.isExtraWork,
          slice && slice.comments
        );
        this.slice.formattedPrice = this.investmentsService.formatSlicePrice(slice);
      }
    }
    this.readOnly = this.investment.status.isValidated;
    this.priceSheets$ = this.investmentsService.getPriceSheets().pipe(
      map(priceSheets =>
        priceSheets.filter(priceSheet => {
          return this.asset && priceSheet.asset_type === this.asset.assetType.id;
        })
      )
    );
    this.selectedInvestmentStatusId = this.investment.status.id;
  }

  private initYears() {
    const startYear = Math.min(this.getStartYear(), new Date().getFullYear());
    const endYear = this.getEndYear();
    for (let year = startYear; year <= endYear; year++) {
      this.years.push(year);
    }
  }

  validateChanges() {
    const sliceIndex = this.investment.slices.findIndex(slice => slice.status.id === this.investment.status.id);
    if (sliceIndex !== -1 && this.slice.status === this.investment.status) {
      // Replace existing slice
      this.investment.slices[sliceIndex] = this.slice;
    } else {
      // Add a new slice with the new status
      this.investment.slices.push(this.slice);
    }
    let saveData = this.investmentsService.getSlicesData(this.investment);
    if (this.investment.investmentBudgetOrigin?.id !== this.selectedBudgetOriginId) {
      // Save the new budget origin
      const selectedBudgetOrigin = this.budgetOrigins.find(origin => origin.id === this.selectedBudgetOriginId);
      if (selectedBudgetOrigin) {
        this.investment.investmentBudgetOrigin = selectedBudgetOrigin;
        saveData["budget_origin"] = selectedBudgetOrigin.id;
      }
    }
    if (this.slice.status.id !== this.investment.status.id) {
      // Save the new status
      saveData["status"] = this.slice.status.id;
      this.investment.status = this.slice.status;
    }
    if (this.budgetScopesChanged) {
      saveData["budget_scopes"] = this.investment.budgetScopes;
    }

    this.subscriptions.push(
      this.investmentsService.patchInvestment(this.asset, this.investment, saveData).subscribe(
        () => {
          this.modalCtrl.dismiss({
            slice: this.slice,
            newStatus: saveData["status"],
          });
        },
        err => {
          this.modalCtrl.dismiss({ slice: this.slice });
        }
      )
    );
  }

  backButtonClicked() {
    this.modalCtrl.dismiss();
  }

  private getStartYear(): number {
    const currentYear = new Date().getFullYear();

    if (this.investment?.finalSchedule) {
      return this.investment.finalSchedule;
    } else if (this.asset) {
      return Math.max(currentYear, this.asset.installationYear);
    }

    return currentYear;
  }

  private getEndYear(): number {
    return Math.max(this.getStartYear() + 20, this.investment.finalScheduleTo, this.defaultYear);
  }

  public onPriceFocus(input: IonInput, slice: FormattedPriceInvestmentSlice | InvestmentSpendingSlice): void {
    input.type = "number";
    let fullPrice = 0;

    if (slice instanceof FormattedPriceInvestmentSlice) {
      fullPrice = getInvestmentSliceFullPrice(slice);
    }

    input.value = "";
    if (fullPrice !== 0) {
      input.value += fullPrice;
    }
  }

  public onPriceBlur(input: IonInput, slice: FormattedPriceInvestmentSlice | InvestmentSpendingSlice): void {
    let newPrice = Number(input.value);
    // We round it to 2 digits or else the backend will reject it
    newPrice = Number(newPrice.toFixed(2));
    let formattedPrice = "";

    if (slice instanceof FormattedPriceInvestmentSlice) {
      if (slice.price != newPrice && !isNaN(newPrice)) {
        this.pendingChanges = true;

        // If the budget origin was "Fiche de prix", change it to default budget origin
        const budgetOrigin = this.budgetOrigins.find(origin => origin.id === this.selectedBudgetOriginId);
        if (budgetOrigin?.price_list) {
          const defaultBudgetOrigin = this.budgetOrigins.find(origin => origin.is_default);
          if (defaultBudgetOrigin) {
            this.selectedBudgetOriginId = defaultBudgetOrigin.id;
          }
        }
      }
      // If it's a locked budget, update the additional price
      if (slice.status.isLocked && slice === this.additionalBudgetEditingSlice) {
        slice.additionalPrice = newPrice - slice.price;
        this.askAdditionalPriceReason(slice);
        this.additionalBudgetEditingSlice = null;
      } else if (!slice.status.isLocked) {
        slice.price = newPrice;
      }
      formattedPrice = this.formatSlicePrice(slice);
      slice.formattedPrice = formattedPrice;

      // For an addition we verify if it's extra work or not
      if (this.addMode) {
        const budgetPlan = this.perimeterBudgetPlans.find(plan => plan.year === slice.year);
        if (budgetPlan && new Date() >= budgetPlan.closeDate) {
          this.displayExtraWorkAlert(slice.year);
          slice.isExtraWork = true;
        }
      }

      this.pendingChanges = true;
      this.sliceChanged();
    }

    // Hack to force the input to go back to a text input with formatted price
    input.type = "text";
    input.value = formattedPrice;
  }

  private async askAdditionalPriceReason(slice: FormattedPriceInvestmentSlice) {
    const alert = await this.alertCtrl.create({
      header: this.translate.instant("What is the reason of this budget change?"),
      backdropDismiss: false,
      inputs: [
        {
          name: "addPriceReason",
          type: "text",
          value: slice.addPriceReason,
        },
      ],
      buttons: [
        {
          text: this.translate.instant("Ok"),
          handler: data => {
            slice.addPriceReason = data.addPriceReason;
          },
        },
      ],
    });
    await alert.present();
  }

  private formatSlicePrice(slice: InvestmentSlice): string {
    let formattedPrice: string;

    slice.price = this.i18n.setMaxValue(slice.price, 12);

    if (slice.additionalPrice) {
      const fullPrice = this.i18n.setMaxValue(slice.price + slice.additionalPrice, 12);
      formattedPrice = `${this.currencyPipe.transform(fullPrice)} (${this.currencyPipe.transform(
        slice.price
      )} + ${this.currencyPipe.transform(slice.additionalPrice)})`;
    } else {
      formattedPrice = this.currencyPipe.transform(slice.price);
    }

    return formattedPrice;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  private displayExtraWorkAlert(year: number): void {
    forkJoin(
      this.translate.get("Extra work"),
      this.translate.get(
        "For {{year}} the budget at the site level is already validated. The new amount will be considered as extra work.",
        { year }
      ),
      this.translate.get("Ok")
    ).subscribe(async ([title, message, okLabel]) => {
      const alert = await this.alertCtrl.create({
        header: title,
        message,
        backdropDismiss: false,
        buttons: [
          {
            text: okLabel,
          },
        ],
      });

      await alert.present();
    });
  }

  public isSliceDisabled(slice: InvestmentSlice) {
    let disabled =
      (slice.status.isLocked && (!this.additionalBudgetEditingSlice || slice !== this.additionalBudgetEditingSlice)) ||
      this.investment?.status.isValidated ||
      this.investment?.status.isLocked;
    return disabled;
  }

  public isSliceValid(): boolean {
    const sliceValid = this.slice.price !== null && this.slice.price > 0 && this.slice.year !== null;
    return sliceValid;
  }

  public sliceChanged() {
    const selectedBudgetOrigin = this.budgetOrigins.find(origin => origin.id === this.selectedBudgetOriginId);
    this.sliceChangedEvent.emit({
      slice: this.slice,
      budgetOrigin: selectedBudgetOrigin,
      budgetScopes: this.investment.budgetScopes,
    });
    this.pendingChanges = true;
  }

  public statusChanged() {
    const selectedStatus = this.investmentStatuses.find(status => status.id === this.selectedInvestmentStatusId);
    if (selectedStatus) {
      this.slice.status = selectedStatus;
      this.pendingChanges = true;
    }
  }

  public async openPriceSheetsList() {
    const priceSheetsModal = await this.modalCtrl.create({
      component: PriceSheetsListPage,
      componentProps: {
        assetTypeId: this.asset ? this.asset.assetType.id : null,
        investmentId: this.investment.id || this.investment.localId || null,
        investment: this.investment,
        asset: this.asset,
        addMode: this.addMode,
        modalMode: true,
      },
    });
    await priceSheetsModal.present();
    await priceSheetsModal.onDidDismiss().then(data => {
      this.subscriptions.push(
        this.priceSheets$.subscribe(priceSheets => {
          const selectedPriceSheet = priceSheets.find(priceSheet => priceSheet.id === this.investment.priceSheet);
          if (selectedPriceSheet) {
            // If the user has selected a price sheet, update the slice budget and
            // select the budget origin "fiche de prix"
            this.slice.price = selectedPriceSheet.unit_price * selectedPriceSheet.quantity;
            this.slice.formattedPrice = this.investmentsService.formatSlicePrice(this.slice);
            const priceSheetbudgetOrigin = this.budgetOrigins.find(origin => origin.price_list);
            if (priceSheetbudgetOrigin) {
              this.selectedBudgetOriginId = priceSheetbudgetOrigin.id;
              this.investment.investmentBudgetOrigin = priceSheetbudgetOrigin;
            }
            this.sliceChanged();
          }
        })
      );
    });
  }

  public changeBudgetScopes() {
    this.sliceChanged();
    this.budgetScopesChanged = true;
    this.pendingChanges = true;
  }

  private getAssetEndOfLifeYear(): number {
    // If we are replacing an asset and if we can get its end of life year, we use it
    // as the default year for the slice
    let assetEndOfLifeYear: number = null;
    if (this.asset && this.addMode && this.investment.investmentType.replacement) {
      const remainingLifeTime = AssetEditService.prototype.getRemainingLifetime(this.asset);
      if (!!remainingLifeTime) {
        assetEndOfLifeYear = new Date().getFullYear() + AssetEditService.prototype.getRemainingLifetime(this.asset);
        this.slice;
      }
    }
    return assetEndOfLifeYear;
  }
}
