import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { AssetEditService } from "@services";
import { AssetAddEvent } from "src/app/pages/asset-add/asset-add.page";
import { ScopeService } from "../../../services/scope.service";
import { Asset, StepsIds } from "../../../structs/assets";
import { AuditQuestion, AuditNotation, AuditNotationItem, NoteEvent } from "../../../structs/audit";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "kpi-notations",
  templateUrl: "./kpi-notations.component.html",
  styleUrls: ["./kpi-notations.component.scss"],
})
export class KpiNotationsComponent implements OnChanges {
  private _asset: Asset;
  @Input() public questions: AuditQuestion[] = [];
  @Output() public changed = new EventEmitter<{ notations: AuditNotation }>();
  @Output() public defectsCount = new EventEmitter<number>();
  @Output() public initEvent = new EventEmitter<AssetAddEvent>();
  public readOnly: boolean = true;

  private auditNotation: AuditNotation;
  private notationBuilt: boolean = false;
  public highlighted: boolean[] = [];
  @Input() public hasEnergyTrajectory: boolean = false;

  constructor(private scope: ScopeService, private assetEditService: AssetEditService) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.asset) {
      this.init();
    }
  }

  @Input()
  get asset() {
    return this._asset;
  }

  set asset(value: any) {
    this._asset = value;
    // this.init()
  }

  public noteUpdated(event: NoteEvent): void {
    const { itemId, note, comment } = event;
    const noteIndex = this.auditNotation.notes.findIndex(note => note.question_item === itemId);
    if (noteIndex > -1) {
      this.auditNotation.notes[noteIndex].note = note;
      this.auditNotation.notes[noteIndex].comment = comment;
      this.updateNotation();
      this.updateDefectCount();
    }
  }

  private init(): void {
    this.scope.getSelectedPerimeter().subscribe(perimeter => (this.readOnly = perimeter.read_only));
    this.buildAuditNotation(this.questions);
    const params = this.getNextStep();
    this.initEvent.emit({
      nextStep: params.nextStep,
      nextLabel: params.nextLabel,
      goNext: false,
      stepValid: true,
    });
  }

  private buildAuditNotation(questions: AuditQuestion[]): void {
    const notes: AuditNotationItem[] = questions.reduce((notes, question) => {
      // mandatory fields don't have default values
      let defaultValue = null;
      if (question.question_type === "on-off") {
        defaultValue = question.mandatory ? null : 1;
      } else if (question.question_type === "stars") {
        defaultValue = question.mandatory ? null : 4;
      } else if (question.question_type === "sliders") {
        defaultValue = question.mandatory ? null : 1;
      } else {
        defaultValue = question.mandatory ? null : 0;
      }
      const questionNotes = question.items.map(item => {
        const itemNote = item.current_note === null ? defaultValue : item.current_note;
        const itemNoteComment = item.current_comment;
        return new AuditNotationItem(item.id, itemNote, question.mandatory, [], itemNoteComment);
      });

      return [].concat(notes, questionNotes);
    }, []);
    this.auditNotation = new AuditNotation(notes);
    if (!this.notationBuilt) {
      // A hack to prevent the defectsCount from being reset to its previous value after we save.
      this.updateDefectCount();
      this.notationBuilt = true;
    }
  }

  private updateNotation() {
    // Update notes on the asset
    this.changed.emit({ notations: this.auditNotation });
  }

  private updateDefectCount() {
    let defectsCount = 0;
    this.auditNotation.notes.forEach(notationItem => {
      if (notationItem.note === 1) {
        defectsCount++;
        this.highlighted[notationItem.question_item] = true;
      } else {
        this.highlighted[notationItem.question_item] = false;
      }
    });
    this.defectsCount.emit(defectsCount);
  }

  private getNextStep() {
    let nextStep = null;
    let nextLabel: string = "next";
    if (this.assetEditService.needsParent()) {
      nextStep = StepsIds.PARENT;
    } else {
      nextStep = 0;
      nextLabel = "addAsset";
    }
    return { nextStep, nextLabel };
  }
}
