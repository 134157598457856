import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { AlertController, ModalController, NavController, NavParams } from "@ionic/angular";
import { InvestmentsService } from "../../../services/investments.service";
import { AssetsService } from "../../../services/assets.service";
import { OfflineService } from "../../../services/offline.service";
import { INVESTMENT_OBJECT, SuccessToastService } from "../../../services/success-toast.service";
import { Asset, Perimeter } from "../../../structs/assets";
import { Investment } from "../../../structs/investments";
import { Events } from "src/app/services/events.service";
import { Observable, combineLatest } from "rxjs";
import * as R from "ramda";
import { map } from "rxjs/operators";
import { cleanFilterString } from "@structs";

@Component({
  selector: "app-investment-modal",
  templateUrl: "./investment-modal.component.html",
  styleUrls: ["./investment-modal.component.scss"],
})
export class InvestmentModalComponent implements OnInit {
  showInvestments: boolean = false;
  showFAB: boolean = false;
  private perimeter: Perimeter = null;
  private asset: Asset = null;
  loading: boolean;
  public filteredInvestments$: Observable<Investment[]>;

  constructor(
    public navCtrl: NavController,
    public navParams: NavParams,
    public modalCtrl: ModalController,
    private investmentsService: InvestmentsService,
    private offlineApi: OfflineService,
    private translate: TranslateService,
    private alertCtrl: AlertController,
    private events: Events,
    private successToast: SuccessToastService
  ) {}

  ngOnInit() {
    this.perimeter = this.navParams.get("perimeter");
    this.asset = this.navParams.get("asset");
    this.searchInvestment();
  }

  dismiss(newInvestment?: boolean) {
    let data = { newInvestment: newInvestment };
    this.modalCtrl.dismiss(data);
  }

  async attachInvestment(investment: Investment) {
    if (
      (investment.category && this.asset.category.id !== investment.category.id) ||
      (investment.subCategory && this.asset.subCategory.id !== investment.subCategory.id)
    ) {
      let nomenclatureAlert = await this.alertCtrl.create({
        header: this.translate.instant("For information"),
        subHeader: this.translate.instant("nomenclature_warning"),
        buttons: [
          {
            text: "OK",
            handler: () => {
              this.doAttachInvestment(investment);
            },
          },
        ],
      });
      await nomenclatureAlert.present();
    } else {
      this.doAttachInvestment(investment);
    }
  }

  private doAttachInvestment(investment: Investment) {
    this.investmentsService.attachInvestmentToAsset(investment, this.asset).subscribe(invest => {
      this.events.publish("updateAssetsInvestmentsTotal", this.asset.investments.length);
      const investmentAttachedMessage = this.translate.instant("The investment has been attached");
      this.successToast.showSuccessToast(INVESTMENT_OBJECT, investmentAttachedMessage);
      this.dismiss();
    });
  }

  public getTitle() {
    let title: string;
    if (!this.showInvestments) {
      title = this.translate.instant("Attach an investment to the asset");
    } else if (this.showInvestments && this.showFAB) {
      title = this.perimeter.name + " > " + this.translate.instant("Attach an investment");
    } else {
      title = this.perimeter.name + " > " + this.translate.instant("New investment");
    }
    return title;
  }

  searchInvestment(event?) {
    let searchText = event ? cleanFilterString(event.target.value) : "";
    if (this.perimeter) {
      this.filteredInvestments$ = combineLatest([
        ...this.perimeter.sub_perimeters.map(subPerimeter =>
          this.offlineApi.getGlobalInvestments(subPerimeter.building_id)
        ),
      ]).pipe(
        map((investmentsBySubPerimeter: Investment[][]) => {
          return R.flatten(investmentsBySubPerimeter).filter(
            (inv: Investment) =>
              (!this.asset ||
                !inv.investmentType.replacement || // OR investment is not a replacement
                !AssetsService.assetHasReplacement(this.asset) || // Asset doesn't have any replacement yet
                !AssetsService.getAssetUsedReplacementLinks(this.asset).some(assetLink =>
                  inv.links.some(l => l.id === assetLink)
                )) && // OR investment links are available for the asset
              (!searchText || cleanFilterString(inv.label).includes(searchText))
          );
        })
      );
    }
  }
}
