import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { CustomerConfig, FullConfig } from "@structs/config";
import { Observable, Subject } from "rxjs";
import { switchMap, catchError, tap, map, shareReplay } from "rxjs/operators";

import { BackendService } from "./backend.service";
import { ErrorsService } from "./errors.service";
import { I18nService } from "./i18n.service";
import { OfflineService } from "./offline.service";
import { GainOrigin, GainPrecision, InitiativeStatus, InvestmentStatus, PriceSheet, RoadmapIndicator } from "@structs";
import { AlertController } from "@ionic/angular";

type ConfigMapping = {
  roadmapIndicators: RoadmapIndicator[];
  priceSheets: PriceSheet[];
  documentTypes: DocumentType[];
  investmentStatus: InvestmentStatus[];
  initiativeStatus: InitiativeStatus[];
  gainOrigins: GainOrigin[];
  gainPrecisions: GainPrecision[];
};

@Injectable()
export class ConfigService {
  constructor(
    private backend: BackendService,
    private errors: ErrorsService,
    private i18n: I18nService,
    private offlineApi: OfflineService,
    private translate: TranslateService,
    private alertCtrl: AlertController
  ) {}

  /**
   * Get a config with a corresponding key
   * @param key configKey
   * @param failIfEmpty
   * @returns
   */
  get<T extends ConfigMapping[K], K extends keyof ConfigMapping>(key: K, failIfEmpty = false): Observable<T> {
    return this.offlineApi.getConfig(key, failIfEmpty);
  }

  public loadConfig(): Observable<any> {
    console.debug("TRYING TO LOAD CONFIG");
    return this.getFullConfig().pipe(
      // CONFIG DOWNLOAD SUCCESS
      switchMap(fullConfig => {
        console.debug("CONFIG DOWNLOADED FROM SERVER");
        this.i18n.setCurrency(fullConfig.currency);

        return this.offlineApi.storeConfig(fullConfig).pipe(
          catchError(() => {
            console.error("ERROR WHILE SAVING CONFIG TO LOCAL STORAGE");
            return this.translate
              .get("Unable to save configuration data due to a network error")
              .pipe(tap(text => this.errors.signalError(text)));
          })
        );
      }),
      // CONFIG DOWNLOAD ERROR
      catchError(() => {
        // IS THERE A CONFIG IN LOCAL STORAGE ?
        return this.offlineApi.getConfigNoRefresh("zones", true).pipe(
          switchMap(() => {
            console.debug("CONFIG LOADED FROM LOCAL STORAGE");
            return this.showErrorDialog();
          }),
          catchError(err => {
            console.error("ERROR WHILE LOADING CONFIG FROM LOCAL STORAGE", {
              error: err,
            });
            // Error no config : inform user
            return this.translate
              .get("Unable to load configuration data due to a network error")
              .pipe(tap(text => this.errors.signalError(text)));
          })
        );
      })
    );
  }

  private getFullConfig(): Observable<FullConfig> {
    return new Observable(observer => {
      this.backend.get("/audit/api/full-config/").subscribe(
        jsonData => {
          observer.next(jsonData);
          observer.complete();
        },
        err => {
          observer.error(err);
          observer.complete();
        }
      );
    });
  }

  private _customerConfig: Observable<CustomerConfig>;
  private get customerConfig$(): Observable<CustomerConfig> {
    if (!this._customerConfig) {
      this._customerConfig = this.backend.get<CustomerConfig[]>("/config/api/customer-config/").pipe(
        map(configs => configs.find(config => config.is_selected)),
        shareReplay(1)
      );
    }
    return this._customerConfig;
  }

  public get showInitiatives$(): Observable<boolean> {
    return this.customerConfig$.pipe(map(config => config.show_initiatives_in_audit));
  }

  public isDisplayed$ = new Subject<boolean>();
  public async showErrorDialog() {
    this.isDisplayed$.next(true);
    const confirm = await this.alertCtrl.create({
      header: this.translate.instant("Data update"),
      message: this.translate.instant("Click on the top left corner to log out and then log back in"),
      backdropDismiss: false,
      buttons: [
        {
          text: this.translate.instant("Ok"),
          handler: () => {
            this.isDisplayed$.next(false);
          },
        },
      ],
    });
    confirm.present();
  }
}
