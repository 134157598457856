import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { combineLatest, from, BehaviorSubject, Observable, Subject, of } from "rxjs";
import {
  Asset,
  AssetFloor,
  AssetPicture,
  AssetType,
  Building,
  Category,
  makeEmptyAsset,
  MissingNotationReason,
  Perimeter,
  PerimeterType,
  SubCategory,
  Zone,
  AuditNotation,
  AuditNotationItem,
  AuditQuestion,
  NoteEvent,
  LEVELS_MAP,
  STATE_LIMITS,
  STATE_LABELS,
  AssetTypeLevel,
} from "@structs";
import { AppSettings } from "@structs";
import { AssetsService } from "./assets.service";
import { AssetPicturesService } from "./asset-pictures.service";
import { AuditService } from "./audit.service";
import { ErrorsService } from "./errors.service";
import { ASSET_OBJECT, SuccessToastService } from "./success-toast.service";
import { AlertController } from "@ionic/angular";
import { User } from "@structs";
import { tap, filter, map, concatMap, toArray, switchMap } from "rxjs/operators";
import { PerimetersService } from "./perimeters.service";
import { DatePipe } from "@angular/common";
import { OfflineService } from "./offline.service";
import { Investment } from "@structs";
import * as moment from "moment";
import { Events } from "./events.service";
import { InitiativeService } from "./initiative.service";

@Injectable()
export class AssetEditService {
  private _newAssetSource = new Subject<Asset>();
  public newAssetSourceItem$ = this._newAssetSource.asObservable();

  private _assetTypeSource = new BehaviorSubject<AssetType>(null);
  public assetTypeSourceItem$ = this._assetTypeSource.asObservable();

  /**
   * List of available assetFloor that an asset can access
   * @private
   */
  private assetFloors = new BehaviorSubject<AssetFloor[]>(null);
  assetFloor$ = this.assetFloors.asObservable().pipe(filter(assetFloor => !!assetFloor));

  /**
   * List of available perimeter types
   */
  private perimeterTypes: PerimeterType[] = null;

  /**
   * List of all assetFloor available from the platform
   * @private
   */
  private assetFloorRepository: AssetFloor[] = null;

  public selectedAssetFloor: number = 99;

  public asset: Asset = null;
  // details segment
  public isOwnershipTypeEnabled: boolean = false;
  public addMode: boolean = false;
  public readOnly: boolean = true;
  public currentPerimeter: Perimeter = null;
  public buildingMonoPerimeter: Perimeter = null;
  // lifecycle segment
  public technicalStateQuestion: AuditQuestion;
  public hasOtherNotations: boolean = false;
  public hasExpertMode: boolean = false;
  public noteHasBeenChanged: boolean = false;
  public reasonHasBeenChanged: boolean = false;
  public hasNotation: boolean = true;
  public expertModeHasBeenChanged: boolean = false;
  public expertModeIsOn: boolean = false;
  public isAutomaticParentshipEnabled: boolean = false;

  private copyPrefix: string;
  private auditNotation: AuditNotation = null;
  // changes we'll make to the asset
  private patchData = {};
  // and new pictures to save
  private newPictures: AssetPicture[] = [];
  private multiPerimeter: Perimeter = null; // site
  private needZone: boolean = false;

  public get remainingLifetimeHasChanged() {
    return this.patchData.hasOwnProperty("durationDeviation");
  }
  public get quantityChanged() {
    return this.patchData.hasOwnProperty("quantity");
  }

  constructor(
    private assetsApi: AssetsService,
    private assetPicturesService: AssetPicturesService,
    private auditApi: AuditService,
    private errors: ErrorsService,
    private successToast: SuccessToastService,
    private translate: TranslateService,
    private alertCtrl: AlertController,
    private perimeterService: PerimetersService,
    private datePipe: DatePipe,
    private event: Events,
    private offlineApi: OfflineService,
    private initiativeService: InitiativeService
  ) {
    combineLatest([this.assetsApi.getOwnershipTypes(), this.assetsApi.getAppSettings()]).subscribe(
      ([ownershipTypes, appSettings]) => {
        ownershipTypes = [];
        if (appSettings) {
          // Disable ownership type if no ownership type is available
          this.isOwnershipTypeEnabled = !!(appSettings.enable_ownership_types && ownershipTypes?.length);
          this.isAutomaticParentshipEnabled = appSettings.enable_automatic_parentship;
        } else {
          this.translate.get("Config missing. refresh the list of perimeters").subscribe(text => {
            this.errors.signalError(text);
          });
        }
      }
    );

    this.translate.get("Copy of").subscribe(text => {
      this.copyPrefix = text;
    });

    this.assetsApi
      .assetFloor$()
      .pipe(
        tap((assetFloors: AssetFloor[]) => (this.assetFloorRepository = assetFloors)),
        tap((assetFloors: AssetFloor[]) => this.assetFloors.next(assetFloors))
      )
      .subscribe();

    this.perimeterService
      .getPerimeterTypes()
      .pipe(tap(perimeterTypes => (this.perimeterTypes = perimeterTypes)))
      .subscribe();

    this.assetsApi.getZones().subscribe(zones => (this.needZone = zones.length > 0));

    this.cleanSlate();
  }

  public setCurrentPerimeter(perimeter: Perimeter, assetToCloneFrom: Asset): void {
    this.cleanSlate();
    this.currentPerimeter = perimeter;
    this.buildingMonoPerimeter = null;

    if (assetToCloneFrom) {
      this.asset.label = this.copyPrefix + " " + assetToCloneFrom.label;
      this.asset.category = assetToCloneFrom.category;
      this.asset.subCategory = assetToCloneFrom.subCategory;
      this.asset.assetType = assetToCloneFrom.assetType;
      this.asset.zone = assetToCloneFrom.zone;
      this.asset.installationYear = assetToCloneFrom.installationYear;
      this.asset.quantity = assetToCloneFrom.quantity;
      this.asset.latestRefrigerantDeclaration = assetToCloneFrom.latestRefrigerantDeclaration;
      this.asset.parent = assetToCloneFrom.parent;
      this.asset.brand = assetToCloneFrom.brand;
      this.asset.model = assetToCloneFrom.model;
      this.asset.powerSource = assetToCloneFrom.powerSource;
      this.asset.power = assetToCloneFrom.power;
      this.asset.floor = assetToCloneFrom.floor;
      this.asset.latest_energy_yield_declaration = assetToCloneFrom.latest_energy_yield_declaration;
      this.asset.heatTransferCoefficientU = assetToCloneFrom.heatTransferCoefficientU;
      this.asset.thermalResistanceCoefficientR = assetToCloneFrom.thermalResistanceCoefficientR;
      this.asset.sourceInfoHeatTransferCoefficientU = assetToCloneFrom.sourceInfoHeatTransferCoefficientU;
      this.asset.sourceInfoThermalResistanceCoefficientR = assetToCloneFrom.sourceInfoThermalResistanceCoefficientR;

      if (assetToCloneFrom.building && this.currentPerimeter.sub_perimeters) {
        this.buildingMonoPerimeter = this.currentPerimeter.sub_perimeters.find(
          monoperim => monoperim.building_id === assetToCloneFrom.building.id
        );
      }
    }
  }

  public setAsset(asset: Asset, readOnly: boolean): void {
    if (
      this.asset.id === asset.id &&
      (this.hasAnythingChanged() || this.noteHasBeenChanged || this.reasonHasBeenChanged)
    ) {
      // if something has changed then we have don't want to wipe out that state
      return;
    }
    this.cleanSlate();
    this.addMode = false;
    this.asset = asset;
    this.readOnly = readOnly;
    this.currentPerimeter = this.asset.building.monosite_perimeter;
    this.selectedAssetFloor = this.selectedAssetFloor || (asset.floor && asset.floor.id);
    if (asset.assetType && asset.assetType.onlyTheseFloors) {
      this.assetFloors.next(
        this.filterAssetFloor({
          assetType: asset.assetType,
          perimeter: asset.building && asset.building.monosite_perimeter,
        })
      );
    }
    this.getAuditQuestionsAndLifetimeDeviationReasons().subscribe();
  }

  public getAuditQuestionsAndLifetimeDeviationReasons(): Observable<void> {
    return new Observable(observer => {
      if (this.asset.id === null) {
        // so we're creating a new asset, but we still want the audit questions to be picked up from the
        // asset type, so let's set the asset type explicitly - feels less like a hack to me than it used to
        this.asset.assetType = this.getAssetType();
      }
      combineLatest(
        this.auditApi.getTechnicalStateKpi(this.asset),
        this.auditApi.getAssetExpertKpiSections(this.asset),
        this.auditApi.getOtherAssetKpis(this.asset)
      ).subscribe(
        ([technicalStateKpi, expertSections, otherKpis]) => {
          if (technicalStateKpi.length > 0) {
            this.technicalStateQuestion = technicalStateKpi[0];
            const { id, current_note } = this.technicalStateQuestion.items[0];
            this.auditNotation = new AuditNotation([
              new AuditNotationItem(id, current_note, true, this.asset.ratingReasons),
            ]);
          }
          if (expertSections.length > 0) {
            this.hasExpertMode = true;
          }
          if (otherKpis.length > 0) {
            this.hasOtherNotations = true;
          }
          observer.next();
          observer.complete();
        },
        err => {
          this.errors.signalError(err);
        }
      );
    });
  }

  public saveNewAsset(addOnly: boolean = false): Observable<Asset> {
    // We reset the value in the behavior subject to avoid issue when opening a new asset page
    this._assetTypeSource.next(null);
    return Observable.create(observer => {
      let data = {
        category: this.getCategory(),
        subCategory: this.getSubCategory(),
        assetType: this.getAssetType(),
        zone: this.getZone(),
        installationYear: this.asset.installationYear,
        label: this.asset.label,
        quantity: this.asset.quantity,
        comments: this.asset.comments,
        durationDeviation: this.asset.durationDeviation,
        qrcode: this.asset.qrcode,
        barcode: this.asset.barcode,
        brand: this.asset.brand,
        model: this.asset.model,
        powerSource: this.asset.powerSource,
        heatTransferCoefficientU: this.asset.heatTransferCoefficientU,
        thermalResistanceCoefficientR: this.asset.thermalResistanceCoefficientR,
        sourceInfoHeatTransferCoefficientU: this.asset.sourceInfoHeatTransferCoefficientU,
        sourceInfoThermalResistanceCoefficientR: this.asset.sourceInfoThermalResistanceCoefficientR,
        power: this.asset.power,
        servedZone: this.asset.servedZone,
        plannedResale: this.asset.plannedResale,
        plannedRecycle: this.asset.plannedRecycle,
        recycleValue: this.asset.recycleValue,
        resaleValue: this.asset.resaleValue,
        height: this.asset.height,
        latestRefrigerantDeclaration: this.asset.latestRefrigerantDeclaration,
        latest_energy_yield_declaration: this.asset.latest_energy_yield_declaration,
        refrigerantType: this.asset.refrigerantType,
        newSiteReplacementStrategy: this.asset.newSiteReplacementStrategy,
        notes: this.asset.notes,
        notes_pictures: this.asset.notesPictures,
        notes_comment: this.asset.notesComment,
        ratingReasons: this.asset.ratingReasons,
        parent: this.asset.parent,
        ownershipType: this.getOwnershipType(),
        zoneDetails: this.asset.zoneDetails,
        notationMissingReason: this.asset.notationMissingReason,
        notationMissingComment: this.asset.notationMissingComment,
        technical_state_changed_by: this.asset.technical_state_changed_by,
        technical_state_changed_on: this.asset.technical_state_changed_on,
        floor: this.getFloor(),
        level: this.asset.level,
        expertMode: this.asset.expertMode,
        equipment_count: this.asset.equipment_count,
        maintenanceCode: this.asset.maintenanceCode,
        step: this.asset.step,
      };

      this.assetsApi.addAsset(this.getPerimeter(), data, this.currentPerimeter.localId).subscribe(
        (addedAsset: Asset) => {
          this._newAssetSource.next(addedAsset);

          this.patchData = {};
          addedAsset.pictures = this.asset.pictures;
          addedAsset.notesPictures = this.asset.notesPictures;
          // @ts-ignore
          addedAsset.notesComment = this.asset.notesComment || {};

          if (addOnly) {
            observer.next(addedAsset);
            observer.complete();
          } else {
            this.updateNote(addedAsset, this.auditNotation).subscribe(
              (newAsset: Asset) => {
                (this.expertModeHasBeenChanged
                  ? this.auditApi.setAuditExpertMode(newAsset, this.expertModeIsOn)
                  : of(newAsset)
                ).subscribe(newAsset2 => {
                  if (this.newPictures.length == 0) {
                    this.successToast.showObjectCreated(ASSET_OBJECT);
                    observer.next(newAsset2);
                  } else {
                    from(this.newPictures)
                      .pipe(
                        map(assetPicture => ({
                          ...assetPicture,
                          asset: addedAsset.id,
                        }))
                      )
                      .pipe(
                        concatMap(assetPicture => this.assetPicturesService.addAssetPicture(assetPicture, addedAsset))
                      )
                      .pipe(toArray())
                      .subscribe(
                        () => {
                          observer.next(newAsset2);
                          this.newPictures = [];
                        },
                        err => this.errors.signalError(err)
                      );
                  }
                });
              },
              err => {
                this.errors.signalError(err);
              }
            );
          }
        },
        err => {
          this.errors.signalError(err);
        }
      );
    });
  }

  public saveNewParentAsset(
    category: Category,
    subCategory: SubCategory,
    parentAssetType: AssetType,
    zone: Zone,
    building: Building,
    step: number,
    label?: string
  ): Observable<Asset> {
    // Create a parent for the current asset
    return Observable.create(observer => {
      let data = {
        category: category,
        subCategory: subCategory,
        assetType: parentAssetType,
        zone: zone,
        building: building,
        installationYear: building.monosite_perimeter.creationYear,
        label: label ? label : parentAssetType.name,
        quantity: parentAssetType.default_quantity !== null ? parentAssetType.default_quantity : 1,
        comments: "",
        durationDeviation: 0,
        qrcode: "",
        barcode: "",
        brand: "",
        model: "",
        powerSource: null,
        power: "",
        servedZone: "",
        newSiteReplacementStrategy: null,
        notes: {},
        ratingReasons: [],
        parent: null,
        ownershipType: null,
        zoneDetails: "",
        notationMissingReason: MissingNotationReason.WAITING_EVALUATION,
        notationMissingComment: "",
        technical_state_changed_by: null,
        technical_state_changed_on: null,
        lastAccess: new Date(),
        step,
      };

      this.assetsApi.addAsset(building.monosite_perimeter, data, building.monosite_perimeter.localId).subscribe(
        (parentAsset: Asset) => {
          this.event.publish("newAsset", parentAsset);
          observer.next(parentAsset);
          observer.complete();
        },
        err => {
          observer.error(err);
          observer.complete();
        }
      );
    });
  }

  public deleteAsset(): Observable<boolean> {
    // We reset the value in the behavior subject to avoid issue when opening a new asset page
    this._assetTypeSource.next(null);
    return Observable.create(observer => {
      this.assetsApi.deleteAsset(this.asset).subscribe(data => {
        this.cleanSlate();
        observer.next(true);
        observer.complete();
      });
    });
  }

  /**
   * Store the asset's investments as global investments
   */
  public storeInvestmentsAsGlobals(): Observable<boolean> {
    this._assetTypeSource.next(null);
    return Observable.create(observer => {
      // Getting the asset's investments
      let investments = [];
      investments = investments.concat(
        this.asset.investments.map(investment => {
          investment.assetId = null;
          investment.assetOffline = false;
          investment.building = this.asset.building;
          investment.monoPerimeterLocalId = this.asset.building.monosite_perimeter.localId;
          investment.category = this.asset.category;
          investment.subCategory = this.asset.subCategory;
          return investment;
        })
      );
      if (this.asset.children && this.asset.children.length > 0) {
        // Getting the children's investments
        this.asset.children.forEach(child => {
          child.investments.forEach(investment => {
            investment.assetId = null;
            investment.assetOffline = false;
            investment.building = child.building;
            investment.monoPerimeterLocalId = child.building.monosite_perimeter.localId;
            investment.category = child.category;
            investment.subCategory = child.subCategory;
            investments.push(investment);
          });
        });
      }
      if (investments.length > 0) {
        this.offlineApi.storeGlobalInvestmentsArray(investments).subscribe(() => {
          observer.next(true);
          observer.complete();
        });
      } else {
        observer.next(true);
        observer.complete();
      }
    });
  }

  public saveLabel(event?): void {
    if (event) AssetEditService.safeStopPropagation(event);
    this.patchData["label"] = this.asset.label;
  }

  public saveNotationMissingReason(): void {
    this.patchData["notation_missing_reason"] = this.asset.notationMissingReason;
  }

  public saveNotationMissingComment(): void {
    this.patchData["notation_missing_comment"] = this.asset.notationMissingComment;
  }

  public saveQrCode(): void {
    this.patchData["qrcode"] = this.asset.qrcode;
  }

  public saveBarCode(isAlsoMaintenanceCode: boolean): void {
    this.patchData["barcode"] = this.asset.barcode;
    if (isAlsoMaintenanceCode) {
      this.asset.maintenanceCode = this.asset.barcode;
      this.patchData["maintenanceCode"] = this.asset.maintenanceCode;
    }
  }

  public saveQuantity(event): void {
    AssetEditService.safeStopPropagation(event);
    if (this.asset.quantity)
      if (this.asset.quantity >= 0) {
        this.asset.quantity = Math.round(this.asset.quantity * 100) / 100;
        this.patchData["quantity"] = this.asset.quantity;
      } else {
        this.translate.get("Quantity is not defined").subscribe(text => {
          this.errors.signalError(text);
        });
      }
  }

  public saveEquipmentCount(): void {
    if (this.asset.equipment_count !== null && !isNaN(this.asset.equipment_count)) {
      if (this.asset.equipment_count >= 0) {
        this.asset.equipment_count = Math.round(this.asset.equipment_count * 100) / 100;
      }
    } else {
      this.asset.equipment_count = null;
      this.errors.signalError(this.translate.instant("Equipment count is not defined"));
    }
    this.patchData["equipment_count"] = this.asset.equipment_count;
  }

  public saveHeight(event): void {
    AssetEditService.safeStopPropagation(event);
    if (this.asset.height)
      if (this.asset.height >= 0) {
        this.asset.height = Math.round(this.asset.height * 100) / 100;
        this.patchData["height"] = this.asset.height;
      } else {
        this.translate.get("height is not defined").subscribe(text => {
          this.errors.signalError(text);
        });
      }
  }

  public saveLatestRefrigerantDeclaration(): void {
    if (!this.asset.latestRefrigerantDeclaration) {
      this.patchData["latestRefrigerantDeclaration"] = this.asset.latestRefrigerantDeclaration;
    } else if (this.asset.latestRefrigerantDeclaration >= 0) {
      this.asset.latestRefrigerantDeclaration = Math.round(this.asset.latestRefrigerantDeclaration * 100) / 100;
      this.patchData["latestRefrigerantDeclaration"] = this.asset.latestRefrigerantDeclaration;
    } else {
      this.translate.get("latest Refrigerant Declaration is not defined").subscribe(text => {
        this.errors.signalError(text);
      });
    }
  }

  public saveEnergyYield(event): void {
    AssetEditService.safeStopPropagation(event);
    if (this.asset.latest_energy_yield_declaration !== null)
      this.patchData["latest_energy_yield_declaration"] = this.asset.latest_energy_yield_declaration;
  }

  /**
   * Update patchData for floor property
   */
  public saveFloor(floor: AssetFloor): void {
    this.asset.floor = floor;
    this.patchData["floor"] = this.asset.floor;
  }

  public saveComments(event): void {
    AssetEditService.safeStopPropagation(event);
    this.patchData["comments"] = this.asset.comments;
  }
  public saveZoneDetails(event): void {
    AssetEditService.safeStopPropagation(event);
    this.patchData["zoneDetails"] = this.asset.zoneDetails;
  }

  public saveMaintenanceCode(event): void {
    AssetEditService.safeStopPropagation(event);
    this.patchData["maintenance_code"] = this.asset.maintenanceCode;
  }

  public zoneChanged(zone): void {
    this.asset.zone = zone;
    this.patchData["zone"] = zone;
  }

  public ownershipTypeChanged(ownershipType): void {
    this.asset.ownershipType = ownershipType;
    this.patchData["ownershipType"] = ownershipType;
  }

  public latestRefrigerantDeclarationChanged(): void {
    this.patchData["latestRefrigerantDeclaration"] = this.asset.latestRefrigerantDeclaration;
  }

  public plannedResaleChanged(): void {
    this.patchData["plannedResale"] = this.asset.plannedResale;
  }

  public plannedRecycleChanged(): void {
    this.patchData["plannedRecycle"] = this.asset.plannedRecycle;
  }

  public recycleValueChanged(): void {
    this.patchData["recycleValue"] = this.asset.recycleValue;
  }

  public resaleValueChanged(): void {
    this.patchData["resaleValue"] = this.asset.resaleValue;
  }

  public categoryChanged(category): void {
    // todo - currently not supported by the patch code
    this.patchData["category"] = category;
  }

  public subcategoryChanged(subcategory): void {
    // todo - currently not supported by the patch code
    this.patchData["subcategory"] = subcategory;
  }

  public assettypeChanged(assetType: AssetType): void {
    this.selectedAssetFloor = 99;
    if (assetType?.defaultFloor) {
      this.selectedAssetFloor = assetType.defaultFloor.id;
    }
    if (assetType?.onlyTheseFloors) {
      this.assetFloors.next(
        this.filterAssetFloor({
          assetType: assetType,
          perimeter: this.currentPerimeter,
        })
      );
    }
    this._assetTypeSource.next(assetType);
    this.patchData["assetType"] = assetType;
  }

  public perimeterChanged(perimeter: Perimeter): void {
    const perimeterType = this.perimeterTypes.find(pt => pt.id === perimeter.perimeterType);
    if (perimeterType && !this.selectedAssetFloor) {
      this.selectedAssetFloor = perimeterType.default_floor;
    }

    // Only show assetFloors that are in the same clusters with perimeter
    this.assetFloors.next(
      this.filterAssetFloor({
        assetType: this.getAssetType(),
        perimeter: perimeter,
      })
    );
    this.buildingMonoPerimeter = perimeter;
    this.currentPerimeter = perimeter;
    let building = new Building(perimeter.building.id, perimeter.building.name, 0, perimeter.name, perimeter);
    this.patchData["building"] = building;
    this.patchData["perimeterLocalId"] = perimeter.localId;
    this.asset.building = building;
    if (this.asset.children.length) {
      this.asset.children.map(child => (child.building = building));
    }
  }

  public installationYearUpdated(): void {
    this.patchData["installationYear"] = this.asset.installationYear;
  }

  public addPicture(picturePath?: string, browserFile?: File): void {
    const assetPicture = this.assetPicturesService.createAssetPicture(this.asset, picturePath, browserFile);
    this.asset.pictures.push(assetPicture);
    this.newPictures.push(assetPicture);
  }

  public deletePicture(picture: AssetPicture): void {
    this.asset.pictures = this.asset.pictures.filter(p => p.localId !== picture.localId);
    this.newPictures = this.newPictures.filter(p => p.localId !== picture.localId);
    if (!this.addMode) {
      this.assetPicturesService.deleteAssetPicture(picture, this.asset).subscribe();
    }
  }

  public durationUpdated(): void {
    this.patchData["durationDeviation"] = this.asset.durationDeviation;
  }

  public budgetReferenceUpdated(): void {
    this.patchData["budgetReference"] = this.asset.budgetReference;
  }

  public importStatusesUpdated(): void {
    this.patchData["importStatuses"] = this.asset.importStatuses;
  }

  public saveBrand(event): void {
    AssetEditService.safeStopPropagation(event);
    this.patchData["brand"] = this.asset.brand;
  }

  public saveModel(event): void {
    AssetEditService.safeStopPropagation(event);
    this.patchData["model"] = this.asset.model;
  }

  public savePower(event): void {
    AssetEditService.safeStopPropagation(event);
    this.patchData["power"] = this.asset.power;
  }

  public saveServedZone(event): void {
    AssetEditService.safeStopPropagation(event);
    this.patchData["servedZone"] = this.asset.servedZone;
  }

  public powerSourceChanged(powerSource): void {
    this.asset.powerSource = powerSource;
    this.patchData["powerSource"] = powerSource;
  }

  public heatTransferCoefficientChanged(heatTransferCoefficientId): void {
    this.asset.heatTransferCoefficientU = heatTransferCoefficientId;
    this.patchData["heatTransferCoefficientU"] = heatTransferCoefficientId;
  }

  public thermalResistanceCoefficientChanged(thermalResistanceCoefficientId): void {
    this.asset.thermalResistanceCoefficientR = thermalResistanceCoefficientId;
    this.patchData["thermalResistanceCoefficientR"] = thermalResistanceCoefficientId;
  }

  public sourceInfoHeatTransferCoefficientChanged(sourceInfoHeatTransferCoefficientId): void {
    this.asset.sourceInfoHeatTransferCoefficientU = sourceInfoHeatTransferCoefficientId;
    this.patchData["sourceInfoHeatTransferCoefficientU"] = sourceInfoHeatTransferCoefficientId;
  }

  public sourceInfoThermalResistanceCoefficientChanged(sourceInfoThermalResistanceCoefficientId): void {
    this.asset.sourceInfoThermalResistanceCoefficientR = sourceInfoThermalResistanceCoefficientId;
    this.patchData["sourceInfoThermalResistanceCoefficientR"] = sourceInfoThermalResistanceCoefficientId;
  }

  public refrigerantTypeChanged(refrigerantType): void {
    this.asset.refrigerantType = refrigerantType;
    this.patchData["refrigerantType"] = refrigerantType;
  }

  public saveNewSiteReplacementStrategy(): void {
    this.patchData["newSiteReplacementStrategy"] = this.asset.newSiteReplacementStrategy;
  }

  public getPerimeter(): Perimeter {
    if (this.patchData.hasOwnProperty("perimeter")) {
      return this.patchData["perimeter"];
    } else if (this.asset != null && this.asset.building != null) {
      return this.asset.building.monosite_perimeter;
    } else if (this.buildingMonoPerimeter) {
      return this.buildingMonoPerimeter;
    } else if (this.currentPerimeter) {
      return this.currentPerimeter;
    }
    return null;
  }

  public getZone(): Zone {
    if (this.patchData.hasOwnProperty("zone")) {
      return this.patchData["zone"];
    } else {
      return this.asset.zone;
    }
  }

  getFloor(): AssetFloor {
    if (this.patchData.hasOwnProperty("floor")) {
      return this.patchData["floor"] as AssetFloor;
    }
    return this.asset.floor;
  }

  public getOwnershipType(): Number {
    if (this.patchData.hasOwnProperty("ownershipType") && this.patchData["ownershipType"] !== null) {
      return this.patchData["ownershipType"].id;
    } else {
      if (this.asset.ownershipType) return this.asset.ownershipType.id;
      else return null;
    }
  }

  public getCategory(): Category {
    if (this.patchData.hasOwnProperty("category")) {
      return this.patchData["category"];
    } else {
      return this.asset.category;
    }
  }

  public getSubCategory(): SubCategory {
    if (this.patchData.hasOwnProperty("subcategory")) {
      return this.patchData["subcategory"];
    } else {
      return this.asset.subCategory;
    }
  }

  public getAssetType(): AssetType {
    if (this.patchData.hasOwnProperty("assetType")) {
      return this.patchData["assetType"];
    } else {
      if (this.asset.assetType) {
        return this.asset.assetType;
      }

      const subCategory = this.getSubCategory();

      if (subCategory && subCategory.children.length === 1) {
        this.assettypeChanged(subCategory.children[0]);
        return subCategory.children[0];
      }
    }

    return null;
  }

  public setOtherNotation(auditNotation: AuditNotation): void {
    if (!this.patchData["notesComment"]) {
      this.patchData["notesComment"] = [];
    }
    for (const note of auditNotation.notes) {
      const existingNoteIndex = this.auditNotation.notes.findIndex(
        existingNote => existingNote.question_item === note.question_item
      );
      if (existingNoteIndex > -1) {
        this.auditNotation.notes[existingNoteIndex].note = note.note;
      } else {
        this.auditNotation.notes.push(note);
      }
      this.patchData["notesComment"][note.question_item] = note.comment;
    }
    this.noteHasBeenChanged = true;
  }

  public noteUpdated(noteEvent: NoteEvent): boolean {
    if (this.readOnly) {
      return;
    }
    let updated = this.setQuestionNotation(noteEvent);
    if (updated) {
      this.noteHasBeenChanged = true;
    }
    return updated;
  }

  /**
   * It can be usefull to have the new technical state saved locally without having to wait
   * for a backend synchronization
   */
  public assetLevelUpdated(newLevel: string) {
    if (this.addMode) {
      this.asset.level = newLevel;
    } else {
      this.patchData["level"] = newLevel;
    }
  }

  public assetLevelFromExternalBaseUpdated(newLevel: string) {
    this.patchData["levelFromExternalBase"] = newLevel;
  }

  public ratingReasonUpdated(reasons: number[]): void {
    if (this.readOnly) {
      return;
    }
    if (this.auditNotation) {
      this.auditNotation.notes[0].rating_reasons = reasons;
      this.reasonHasBeenChanged = true;
      this.asset.ratingReasons = reasons;
    }
  }

  private cleanSlate(): void {
    // we re-use this page from a variety of places so have one place to wipe out all the state
    this._assetTypeSource.next(null);

    this.technicalStateQuestion = null;
    this.hasOtherNotations = false;
    this.hasExpertMode = false;
    this.auditNotation = null;
    this.noteHasBeenChanged = false;
    this.reasonHasBeenChanged = false;
    this.expertModeIsOn = false;
    this.expertModeHasBeenChanged = false;
    this.addMode = true;
    this.readOnly = false;
    this.currentPerimeter = null;
    this.patchData = {};
    this.newPictures = [];
    this.buildingMonoPerimeter = null;
    this.selectedAssetFloor = null;

    this.asset = makeEmptyAsset();
  }

  private hasAnythingChanged(): boolean {
    let anythingChanged: boolean = false;
    for (let prop in this.patchData) {
      if (this.patchData.hasOwnProperty(prop)) {
        anythingChanged = true;
        break;
      }
    }
    return anythingChanged;
  }

  private setQuestionNotation(noteEvent: NoteEvent): boolean {
    let updated = false;

    const noteToUpdate = this.auditNotation.notes.find(note => note.question_item === noteEvent.itemId);
    if (noteToUpdate && noteToUpdate.note !== noteEvent.note) {
      noteToUpdate.note = noteEvent.note;
      this.asset.notes[noteEvent.itemId] = noteEvent.note;
      updated = true;
    }

    return updated;
  }

  private updateNote(asset: Asset, notation: AuditNotation): Observable<Asset> {
    return Observable.create(observer => {
      // update current asset
      asset.ratingReasons = notation.notes[0].rating_reasons;
      for (let i = 0, l = notation.notes.length; i < l; i++) {
        let notationItem: AuditNotationItem = notation.notes[i];
        asset.notes[notationItem.question_item] = notationItem.note;
        asset.notesComment[notationItem.question_item] = notationItem.comment;
      }

      this.auditApi.setAuditNote(asset, notation).subscribe(
        done => {
          observer.next(asset);
          observer.complete();
        },
        err => {
          observer.error(err);
          observer.complete();
        }
      );
    });
  }

  public isDetailValid(): boolean {
    let valid =
      this.asset.label &&
      this.asset.quantity > 0 &&
      this.getPerimeter() &&
      this.getPerimeter().is_monosite &&
      (this.getZone() || !this.needZone) &&
      this.getCategory() !== null &&
      this.getSubCategory() !== null &&
      this.getAssetType() !== null &&
      (!this.getAssetType().show_equipment_count || this.asset.equipment_count !== null) &&
      (!this.isOwnershipTypeEnabled || this.getOwnershipType() !== null) &&
      (!this.getAssetType().heightSupport || this.asset.height !== null);
    return valid;
  }

  /**
   * To check if all the mandatory fields are ok in the lifecycle tab. If it
   * doesn't work, you can use the debugLifecycle() function.
   */
  public isLifecycleValid(): boolean {
    if (this.hasNotation) {
      let notesDefined = true;
      let hasNotes = false;
      // Check that the asset has no null as value of a KPI
      for (const [key, value] of Object.entries(this.asset.notes)) {
        if (value === null) {
          notesDefined = false;
          // A KPI is null : it is not valid
          break;
        } else {
          hasNotes = true;
        }
      }
      return (
        ((this.addMode && this.noteHasBeenChanged) || !this.addMode) &&
        this.asset.installationYear !== null &&
        this.asset.durationDeviation !== null &&
        Number.isInteger(this.asset.durationDeviation) &&
        hasNotes &&
        notesDefined &&
        this.asset.ratingReasons !== null &&
        this.asset.ratingReasons.length > 0
      );
    } else {
      return this.asset.installationYear && this.asset.notationMissingReason !== MissingNotationReason.NOT_SET;
    }
  }

  public async showLifecycleWarning() {
    let alertDlg = await this.alertCtrl.create({
      header: this.translate.instant("Missing info warning"),
      message: this.translate.instant("Mandatory information about lifecycle are missing"),
      backdropDismiss: false,
      buttons: [
        {
          text: this.translate.instant("OK"),
          handler: () => {},
        },
      ],
    });
    await alertDlg.present();
  }

  resetAsset() {
    this.cleanSlate();
  }

  public isExpertModeOn(user: User): boolean {
    return !!this.asset.expertMode[user.get_user_id];
  }

  public setExpertMode(user: User, isOn: boolean) {
    this.expertModeHasBeenChanged = true;
    this.expertModeIsOn = isOn; // set value for API call
    this.asset.expertMode[user.get_user_id] = isOn; // update the local asset
    this.patchData["expertMode"] = this.asset.expertMode;
  }
  /**
   * We don't really need to save the user and date of the new audit, as the backend does it automatically.
   * But this is to apply the changes locally, so the user can see the new audit sentence immediately,
   * without having to synchronize all the assets.
   * @param userId
   * @param date
   */
  public updateLastAuditUserAndDate(userId: number, date: any) {
    this.asset.technical_state_changed_by = userId;
    this.asset.technical_state_changed_on = date;
  }

  /**
   * Filtering AssetFloor according to a condition
   * The condition is a dict contains two objects: assetType & perimeter
   * This method is pure, it has has **NO** side-effect
   * @private
   * @param condition
   */
  private filterAssetFloor(condition: { assetType?: AssetType; perimeter?: Perimeter }): AssetFloor[] {
    const { assetType, perimeter } = condition;
    let assetFloors = this.assetFloorRepository;
    if (assetType) {
      assetFloors =
        assetFloors && assetFloors.length
          ? assetFloors.filter(
              assetFloor =>
                (assetType.onlyTheseFloors && assetType.onlyTheseFloors.length === 0) ||
                assetType.onlyTheseFloors.includes(assetFloor.id)
            )
          : [];
    }

    if (perimeter) {
      assetFloors =
        assetFloors && assetFloors.length
          ? assetFloors.filter(assetFloor => {
              return (
                !assetFloor.only_for_clusters ||
                !assetFloor.only_for_clusters.length ||
                (assetFloor.only_for_clusters.length && assetFloor.only_for_clusters.indexOf(perimeter.cluster) !== -1)
              );
            })
          : [];
    }
    return assetFloors;
  }

  /**
   * Looking at the available assetFloor and search for the corresponding id
   * @param assetFloorId
   */
  public findAssetFloor(assetFloorId: number): AssetFloor {
    return this.assetFloors.getValue().find(assetFloor => assetFloor.id == assetFloorId);
  }

  /**
   * We don't really need to update the last access date, as the backend does it already, but we
   * do it locally to have the result faster. So the assets list will be sorted as soon as
   * the user gets back to it.
   */
  public updateLastAccess() {
    let date = moment().format();
    this.asset.lastAccess = new Date(date);

    /**
     * Force the asset and its parent's last access to be refreshed in order to retrieve
     * them at the top of the perimeter assets list
     */
    const patchData = { lastAccess: new Date() };
    this.auditApi.patchAsset(this.asset, patchData).subscribe();
    if (this.asset.parent) {
      this.auditApi.getAssets().subscribe(assets => {
        const parent: Asset = assets.find(a => a.id === this.asset.parent.id);
        this.auditApi.patchAsset(parent, patchData).subscribe();
      });
    }
  }

  /**
   * We often have a bug about an unknown pending change when we open the asset sheet.
   * With this function it will be easier to know what's going on.
   */
  public logPatchData() {
    console.log("patchData", this.patchData);
  }

  public applyInvestmentNoteToAsset(investment: Investment, ratingReasonsIds: number[]): Observable<Asset> {
    return new Observable(observer => {
      this.auditApi.getAuditQuestions(this.asset, investment).subscribe(questions => {
        const question = questions.find(q => q.mnemonic === "technical_state");
        const item = question.items[0];
        // Update note and reasons
        const noteToUpdate = this.auditNotation.notes.find(note => note.question_item === item.id);
        if (noteToUpdate) {
          noteToUpdate.note = item.current_note;
          noteToUpdate.rating_reasons = ratingReasonsIds;
          this.asset.notes[item.id] = item.current_note;
          this.asset.ratingReasons = ratingReasonsIds;
          this.noteHasBeenChanged = true;
          this.reasonHasBeenChanged = true;
        }
        // Update level
        const noteOutOf100 = LEVELS_MAP[item.current_note];
        const stateLevel = STATE_LIMITS.findIndex(n => n >= noteOutOf100);
        const stateLevelLabel = STATE_LABELS[stateLevel];
        this.assetLevelUpdated(stateLevelLabel.toLowerCase());
        // Update investment field
        let investmentIndex = this.asset.investments.findIndex(invest => invest.id === investment.id);
        if (!investmentIndex) {
          investmentIndex = this.asset.investments.findIndex(invest => invest.localId === investment.localId);
        }
        this.asset.investments[investmentIndex] = investment;
        // // Save
        // this.saveAsset();
        // this.event.publish("ignorePendingChanges");
        observer.next(this.asset);
        observer.complete();
      });
    });
  }

  public saveAsset(): Observable<Asset> {
    // We reset the value in the behavior subject to avoid issue when opening a new asset page
    this._assetTypeSource.next(null);

    // chain together patches and note updates so that new assets who subsequently have their notation changed stick.
    return (this.hasAnythingChanged() ? this.auditApi.patchAsset(this.asset, this.patchData) : of(this.asset)).pipe(
      switchMap(asset => {
        this.patchData = {};
        return this.noteHasBeenChanged || this.reasonHasBeenChanged
          ? this.updateNote(asset, this.auditNotation)
          : of(asset);
      }),
      switchMap(asset => {
        this.noteHasBeenChanged = false;
        this.reasonHasBeenChanged = false;
        return this.expertModeHasBeenChanged ? this.auditApi.setAuditExpertMode(asset, this.expertModeIsOn) : of(asset);
      }),
      switchMap(asset => {
        this.expertModeHasBeenChanged = false;
        this.expertModeIsOn = false;
        if (this.newPictures.length > 0) {
          return from(this.newPictures).pipe(
            concatMap(assetPicture => this.assetPicturesService.addAssetPicture(assetPicture, asset)),
            tap(asset => {
              this.newPictures = [];
            })
          );
        } else {
          return of(asset);
        }
      })
    );
  }

  /**
   * In case the lifecycle validation doesn't work and you need to log
   * all the variables to understand what's going on
   */
  public debugLifeCycle() {
    console.log("this.hasNotation", this.hasNotation);
    if (this.hasNotation) {
      let notesDefined = true;
      let hasNotes = false;
      // Check that the asset has no null as value of a KPI
      for (const [key, value] of Object.entries(this.asset.notes)) {
        if (value === null) {
          notesDefined = false;
          // A KPI is null : it is not valid
          break;
        } else {
          hasNotes = true;
        }
      }
      console.log("this.addMode", this.addMode);
      console.log("this.noteHasBeenChanged", this.noteHasBeenChanged);
      console.log("this.asset.installationYear", this.asset.installationYear);
      console.log("this.asset.durationDeviation", this.asset.durationDeviation);
      console.log("Number.isInteger(this.asset.durationDeviation)", Number.isInteger(this.asset.durationDeviation));
      console.log("hasNotes", hasNotes);
      console.log("notesDefined", notesDefined);
      console.log("this.asset.ratingReasons", this.asset.ratingReasons);
    } else {
      console.log("this.asset.installationYear", this.asset.installationYear);
      console.log("this.asset.notationMissingReason", this.asset.notationMissingReason);
    }
  }

  public setExpertPictures(questionId: number, pictures: Array<any>) {
    this.asset.notesPictures[questionId] = pictures;
    if (!this.addMode) {
      const patchData = { notesPictures: this.asset.notesPictures };
      this.auditApi.patchAsset(this.asset, patchData).subscribe();
    }
  }

  /**
   * backport for ionic 3, some update properties need to stop propagate
   * its actions but the caller never follows the signature so we need to
   * guard the function
   * @param event
   * @private
   */
  private static safeStopPropagation(event?) {
    if (!event) return;
    if (typeof event.stopPropagation === "function") {
      event.stopPropagation();
    }
    if (typeof event.preventDefault === "function") {
      event.preventDefault();
    }
  }

  public needsParent(): boolean {
    let assetType = this.getAssetType();
    let parent = this.asset.parent;
    let needsParent =
      assetType !== null &&
      parent === null &&
      (assetType.level === AssetTypeLevel.LEVEL_COLLECTION_ITEM || assetType.level === AssetTypeLevel.LEVEL_COMPONENT);
    return needsParent;
  }

  setMultiPerimeter(multiPerimeter: Perimeter) {
    this.multiPerimeter = multiPerimeter;
  }

  public getRemainingLifetime(asset: Asset): number {
    if (asset.installationYear) {
      const currentYear = new Date().getFullYear();
      const assetAge = currentYear - asset.installationYear;
      return Math.max(asset.assetType.expected_duration + asset.durationDeviation - assetAge, 0);
    }
    return null;
  }
}
