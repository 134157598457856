import { Injectable } from "@angular/core";
import { empty, Observable, of } from "rxjs";

import { Environment } from "../app.environment";
import { Change, ChangeAction, makeChange } from "@structs/synchronization";
import { getLocalId } from "../structs/utils";
import { BackendService } from "./backend.service";
import { OfflineService } from "./offline.service";
import { PicturesService } from "./pictures.service";
import { PicturesLoaderService } from "./pictures-loader.service";
import { SynchronizationService } from "./synchronization.service";
import { Investment, InvestmentPicture, makeInvestmentPicture } from "../structs/investments";
import { Asset } from "../structs/assets";
import { catchError, concatMap, expand, filter, map, switchMap, tap, toArray } from "rxjs/operators";

/**
 * Structure of a paginated API response.
 */
interface Pagination<T> {
  count: number;
  next: string;
  previous: string;
  results: T[];
}

@Injectable()
export class InvestmentPicturesService {
  constructor(
    private backendService: BackendService,
    private offlineApi: OfflineService,
    private picturesLoaderService: PicturesLoaderService,
    private picturesService: PicturesService,
    private synchronizationService: SynchronizationService
  ) {}

  public createInvestmentPicture(investment: Investment, filePath?: string, browserFile?: File): InvestmentPicture {
    return new InvestmentPicture(0, investment.id, null, null, null, "", getLocalId(), "", filePath, browserFile);
  }

  public addInvestmentPicture(
    investmentPicture: InvestmentPicture,
    investment: Investment,
    asset: Asset = null
  ): Observable<Investment> {
    if (investmentPicture.browserFile) {
      this.picturesService.setBrowserFile(investmentPicture.browserFile, investmentPicture.localId);
    }

    return (
      this.synchronizationService
        .addChange(
          makeChange(
            ChangeAction.addInvestmentPictureAction,
            "/investments/api/investment-pictures/",
            "post",
            investmentPicture,
            asset,
            investmentPicture.localId,
            investment
          )
          // Add the new picture to the local investment...
        )
        .pipe(
          map(() => {
            investment.setPictures([
              ...investment.pictures,
              // ...only if not already there
              ...(investment.pictures.findIndex(picture => picture.localId === investmentPicture.localId) < 0
                ? [investmentPicture]
                : []),
            ]);
            return investment;
          })
        )
        .pipe(
          concatMap(updatedInvestment =>
            this.synchronizationService.signalOfflineChanges().pipe(map(() => updatedInvestment))
          )
        )
        // Save the updated investment in the offline cache
        .pipe(
          concatMap(updatedInvestment => {
            if (asset) {
              const assetInvs = asset.investments;
              const invAsset = assetInvs.findIndex(inv => inv.id === updatedInvestment.id);
              assetInvs[invAsset] = updatedInvestment;
              this.offlineApi.storeAssetInvestments(asset, assetInvs).subscribe();
            }
            return of(updatedInvestment);
          })
        )
    );
  }

  /**
   * Add the Change object to the Change Repository
   * @param investmentPicture
   * @param asset
   * @private
   */
  private addChangeToDeleteInvestmentPicture(investmentPicture: InvestmentPicture, asset: Asset): Observable<Change> {
    const change = makeChange(
      ChangeAction.deleteInvestmentPictureAction,
      `/investments/api/investment-pictures/localId/${investmentPicture.localId}/`,
      "delete",
      {}
    );
    return this.synchronizationService.addChange(change);
  }

  /**
   * Remove the picture from Investment.InvestmentPicture[]
   * @param investment
   * @param investmentPicture
   * @private
   */
  private removePictureFromInvestment(investment: Investment, investmentPicture: InvestmentPicture): Investment {
    const updatedPictures = investment.pictures.filter(picture => picture.localId !== investmentPicture.localId);
    investment.setPictures(updatedPictures);
    return investment;
  }

  /**
   * Save the asset object with the investment to localStorage.
   * If the Investment is not found in the list of Asset.Investment[], this action will be discarded
   * @param updatedInvestment: Investment object that being updated into the asset
   * @param asset
   * @private
   */
  private updateAssetWithInvestment(updatedInvestment: Investment, asset?: Asset): Observable<Investment> {
    if (!asset) {
      return of(updatedInvestment);
    }
    const assetInvestments = asset.investments.map(investment =>
      investment.id === updatedInvestment.id ? updatedInvestment : investment
    );
    return this.offlineApi.storeAssetInvestments(asset, assetInvestments).pipe(map(() => updatedInvestment));
  }

  /**
   * - Add deleteInvestmentPictureAction to sync service
   * - Trigger the sync service to run
   * - Update the Investment/Asset corresponding
   * @param investmentPicture: The InvestmentPicture that are going to be deleted
   * @param investment: The Investment object
   * @param asset: The Asset object, we will update the asset to the localStorage
   */
  public deleteInvestmentPicture(
    investmentPicture: InvestmentPicture,
    investment: Investment,
    asset: Asset = null
  ): Observable<Investment> {
    return this.synchronizationService.getChanges(true).pipe(
      switchMap(changes => this.addChangeToDeleteInvestmentPicture(investmentPicture, asset)),
      tap(() => this.synchronizationService.signalOfflineChanges().subscribe()),
      map(() => this.removePictureFromInvestment(investment, investmentPicture)),
      concatMap(updatedInvestment => this.updateAssetWithInvestment(updatedInvestment, asset))
    );
  }

  /**
   * Refreshes the investment pictures from the API.
   * This way, we get again pictures URL with a fresh access token (which expires every 30 minutes).
   *
   * @param investmentIds The ids of the investments to refresh the pictures for.
   */
  public refreshInvestmentPictures(investmentIds: number[]): Observable<Investment[]> {
    const investmentPicturesObservable: Observable<Pagination<InvestmentPicture>> = this.backendService.get(
      "/investments/api/investment-pictures/",
      {
        investments: investmentIds.join(","),
        size: 500,
      }
    );

    // Call the API recursively to get all pages of investment pictures
    return (
      investmentPicturesObservable
        .pipe(
          expand(({ next }) => {
            if (next) {
              const nextURL = next.replace(Environment.getBackendHost(), "");
              return <Observable<Pagination<InvestmentPicture>>>this.backendService.get(nextURL);
            } else {
              return empty();
            }
          })
        )
        .pipe(concatMap(({ results }) => results))
        // Retrieve the offline investment and update its pictures
        .pipe(
          concatMap(investmentPicture =>
            this.offlineApi
              .getInvestment(investmentPicture.investment)
              .pipe(filter(investment => investment !== null))
              .pipe(
                map(investment => {
                  const investmentPictureObject = makeInvestmentPicture(investmentPicture);
                  const investmentPictureIndex = investment.pictures.findIndex(
                    picture =>
                      picture.id === investmentPicture.id || picture.localId === investmentPictureObject.localId
                  );
                  if (investmentPictureIndex > -1) {
                    investment.pictures[investmentPictureIndex] = investmentPictureObject;
                  } else {
                    investment.pictures.push(investmentPictureObject);
                  }

                  // this.picturesLoaderService.preloadThumbnails(investment.pictures);

                  return investment;
                })
              )
              // Save the updated investment in offline cache
              .pipe(concatMap(updatedInvestment => this.offlineApi.storeInvestments([updatedInvestment])))
          )
        )
        .pipe(toArray())
        .pipe(catchError(() => []))
    );
  }

  setBrowserFile(newPicture: InvestmentPicture) {
    this.picturesService.setBrowserFile(newPicture.browserFile, newPicture.localId);
  }
}
