import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { Asset, AssetTab, Perimeter } from "../../../structs/assets";
import { Investment } from "../../../structs/investments";
import { Observable, Subscription } from "rxjs";
import { AppSettings } from "../../../structs/base";
import { AssetsService } from "../../../services/assets.service";
import { Events } from "src/app/services/events.service";
import { NavigationExtras, Router } from "@angular/router";
import { ScopeService } from "@services/scope.service";
import { RoadmapIndicator } from "@structs";
import { ConfigService } from "@services/config.service";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "investments-list-segment",
  templateUrl: "./investments-list.component.html",
  styleUrls: ["./investments-list.component.scss"],
})
export class InvestmentsListSegmentComponent implements OnInit, OnDestroy {
  @Input() investments: Array<Investment> = [];
  loading: boolean = true;
  assetId: number = 0;
  @Input() watcher: Observable<AssetTab> = null;
  @Input() asset: Asset = null;
  watcherSubscription: Subscription = null;
  isAssetAffiliationEnabled: boolean = false;
  showChildrenInvestmentsToggle: boolean = false;
  showChildrenInvestments: boolean = false;
  @Output() public investmentDeletedEvent = new EventEmitter<number>();
  public roadmapIndicators$ = this.config.get("roadmapIndicators");

  constructor(
    private assetsApi: AssetsService,
    private router: Router,
    private scope: ScopeService,
    private config: ConfigService
  ) {}

  ngOnInit() {
    this.assetsApi.getAppSettings().subscribe((appSettings: AppSettings) => {
      if (appSettings) {
        this.isAssetAffiliationEnabled = !!appSettings.enable_asset_affiliation;
        this.showChildrenInvestments = true;
      }
    });

    this.watcherSubscription = this.watcher.subscribe(tab => {
      if (tab.tabName === "investments") {
        this.asset = tab.asset;
        this.showChildrenInvestmentsToggle = this.isAssetAffiliationEnabled && this.asset.children.length > 0;
        this.sortInvestments();
      }
    });
  }

  ngOnDestroy() {
    this.watcherSubscription.unsubscribe();
  }

  showInvestment(investment: Investment) {
    this.scope.getCurrentMultiPerimeter().subscribe(multiperimeter => {
      let navigationExtras: NavigationExtras = {
        state: {
          showLink: false,
        },
      };
      this.router.navigate(
        ["perimeters", multiperimeter.id, "investment-detail", investment.id || investment.localId],
        navigationExtras
      );
    });
  }

  public investmentDeleted() {
    this.investmentDeletedEvent.emit();
  }

  public sortInvestments() {
    this.loading = true;
    this.investments.sort((invest1: Investment, invest2: Investment) => {
      let year1 = invest1.finalSchedule;
      let year2 = invest2.finalSchedule;
      let yearTo1 = invest1.finalScheduleTo;
      let yearTo2 = invest2.finalScheduleTo;
      if (year1 < year2) {
        return -1;
      } else if (year1 > year2) {
        return 1;
      } else {
        if (yearTo1 < yearTo2) {
          return -1;
        } else if (yearTo1 > yearTo2) {
          return 1;
        } else {
          return 0;
        }
      }
    });
    this.loading = false;
  }
}
